var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "LBL0010201" },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: "",
                              editable: _vm.editable,
                              disabled: _vm.disabled,
                              label: "LBL0010198",
                              name: "proceedingsTitle",
                            },
                            model: {
                              value: _vm.data.proceedingsTitle,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "proceedingsTitle", $$v)
                              },
                              expression: "data.proceedingsTitle",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: "",
                              editable: _vm.editable,
                              disabled: _vm.disabled,
                              label: "LBL0010199",
                              name: "proceedingsDate",
                            },
                            model: {
                              value: _vm.data.proceedingsDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "proceedingsDate", $$v)
                              },
                              expression: "data.proceedingsDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: "",
                              editable: _vm.editable,
                              disabled: _vm.disabled,
                              type: "edit",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.data.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "plantCd", $$v)
                              },
                              expression: "data.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-dept", {
                            attrs: {
                              required: true,
                              disabled: _vm.disabled,
                              editable: _vm.editable,
                              label: "작성부서",
                              name: "deptCd",
                            },
                            model: {
                              value: _vm.data.deptCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "deptCd", $$v)
                              },
                              expression: "data.deptCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: "",
                              editable: _vm.editable,
                              disabled: _vm.disabled,
                              label: "의견접수 마감일",
                              name: "opinionDt",
                            },
                            model: {
                              value: _vm.data.opinionDt,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "opinionDt", $$v)
                              },
                              expression: "data.opinionDt",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.editable,
                              disabled: _vm.disabled,
                              rows: 2,
                              label: "LBL0010202",
                              name: "proceedingsContents",
                            },
                            model: {
                              value: _vm.data.proceedingsContents,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "proceedingsContents", $$v)
                              },
                              expression: "data.proceedingsContents",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c(
                  "c-table",
                  {
                    ref: "inTable",
                    attrs: {
                      title: "회사측 참석자 목록",
                      columns: _vm.inGrid.columns,
                      gridHeight: _vm.gridHeight,
                      data: _vm.data.attendeeCoModels,
                      filtering: false,
                      columnSetting: false,
                      usePaging: false,
                      noDataLabel: "회사측 참석자를 추가하세요",
                      hideBottom: true,
                      editable: _vm.editable && !_vm.disabled,
                      checkClickFlag: false,
                      isExcelDown: false,
                      rowKey: "proceedingsAttendeeId",
                      selection: "multiple",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "customArea",
                        fn: function ({ props, col }) {
                          return [
                            col.name === "click"
                              ? [
                                  _c(
                                    "q-btn",
                                    {
                                      attrs: {
                                        round: "",
                                        unelevated: "",
                                        size: "10px",
                                        color: "amber",
                                        icon: "search",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return (() => {
                                            ;(_vm.rowIndex = props.rowIndex),
                                              (_vm.electronSignature =
                                                props.row.electronSignature)
                                          }).apply(null, arguments)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "q-popup-proxy",
                                        {
                                          ref: "proxy" + props.rowIndex,
                                          attrs: { breakpoint: 400 },
                                        },
                                        [
                                          _c(col.component, {
                                            tag: "component",
                                            attrs: {
                                              param: props.row,
                                              rowIndex: props.rowIndex,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "template",
                      { slot: "table-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable && !_vm.disabled
                              ? _c("c-btn", {
                                  attrs: {
                                    showLoading: false,
                                    label: "LBLADD",
                                    icon: "add",
                                  },
                                  on: { btnClicked: _vm.addCoAttendee },
                                })
                              : _vm._e(),
                            _vm.editable &&
                            !_vm.disabled &&
                            _vm.data.attendeeCoModels.length > 0
                              ? _c("c-btn", {
                                  attrs: {
                                    showLoading: false,
                                    label: "제외",
                                    icon: "remove",
                                  },
                                  on: { btnClicked: _vm.removeCoAttendee },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c(
                  "c-table",
                  {
                    ref: "outTable",
                    attrs: {
                      title: "근로자측 참석자 목록",
                      columns: _vm.inGrid.columns,
                      gridHeight: _vm.gridHeight,
                      data: _vm.data.attendeeEmpModels,
                      filtering: false,
                      columnSetting: false,
                      usePaging: false,
                      noDataLabel: "근로자측 참석자를 추가하세요",
                      hideBottom: true,
                      checkClickFlag: false,
                      isExcelDown: false,
                      editable: _vm.editable && !_vm.disabled,
                      rowKey: "proceedingsAttendeeId",
                      selection: "multiple",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "customArea",
                        fn: function ({ props, col }) {
                          return [
                            col.name === "click"
                              ? [
                                  _c(
                                    "q-btn",
                                    {
                                      attrs: {
                                        round: "",
                                        unelevated: "",
                                        size: "10px",
                                        color: "amber",
                                        icon: "search",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return (() => {
                                            ;(_vm.rowIndex = props.rowIndex),
                                              (_vm.electronSignature =
                                                props.row.electronSignature)
                                          }).apply(null, arguments)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "q-popup-proxy",
                                        {
                                          ref: "proxy" + props.rowIndex,
                                          attrs: { breakpoint: 400 },
                                        },
                                        [
                                          _c(col.component, {
                                            tag: "component",
                                            attrs: {
                                              param: props.row,
                                              rowIndex: props.rowIndex,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "template",
                      { slot: "table-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable && !_vm.disabled
                              ? _c("c-btn", {
                                  attrs: {
                                    showLoading: false,
                                    label: "LBLADD",
                                    icon: "add",
                                  },
                                  on: { btnClicked: _vm.addEmpAttendee },
                                })
                              : _vm._e(),
                            _vm.editable &&
                            !_vm.disabled &&
                            _vm.data.attendeeEmpModels.length > 0
                              ? _c("c-btn", {
                                  attrs: {
                                    showLoading: false,
                                    label: "제외",
                                    icon: "remove",
                                  },
                                  on: { btnClicked: _vm.removeEmpAttendee },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-table", {
                  ref: "subjectGrid",
                  attrs: {
                    title: "회의 항목",
                    columns: _vm.subjectGrid.columns,
                    merge: _vm.subjectGrid.merge,
                    gridHeight: _vm.gridHeight,
                    data: _vm.data.opinions,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    hideBottom: true,
                    checkClickFlag: false,
                    isExcelDown: false,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }